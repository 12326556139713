export const dischargeSummaryTemplateString = `
(formData, patient, hospital,formConfig, ref) => {
  const hasComorbidities = formData.comorbidities && formData.comorbidities.some((c) => c.name);
  const hasMedicineAdvice = formData.medicineAdvice && formData.medicineAdvice.some((m) => m.name);
  const hasInvestigations = formData.investigations && formData.investigations.some((i) => i.name || i.category);
  const comorbiditiesString = formData.comorbidities?.filter((c) => c.name).map((c) => c.name).join(", ");

  const appendComorbidities = (content, type) => {
    if (!hasComorbidities || formData.comorbidityHandling === "separate") {
      return content;
    }
    if (type === formData.comorbidityHandling) {
      return \`\${content}\${content ? ", " : ""} \${comorbiditiesString}\`;
    }
    return content;
  };

  const formatLabel = (label) => {
    if (!label) return "";
    const regex = /^\([^)]+\)|^(?:\\S+\\s?){1,3}/;
    const match = label.match(regex);
    return match ? match[0].trim() : label;
  };

  const formatDate = (date) => {
    if (!date) return "--";
    return new Date(date).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
  };

  const hasValue = (obj) => {
    if (!obj) return false;
    return Object.values(obj).some(value => value !== "" && value !== null && value !== undefined);
  };

  return React.createElement("div", { ref: ref, style: { padding: "10px" }, className: "print-content" },
    // Header with purple background
    React.createElement("div", { style: { backgroundColor: "#663399", color: "white", padding: "6px" } },
      React.createElement(HospitalHeader, { hospitalInfo: hospital })
    ),

    // Discharge Summary Title
    React.createElement("div", { 
      style: { 
        backgroundColor: "#663399", 
        color: "white", 
        padding: "4px 8px", 
        textAlign: "center",
        marginBottom: "4px",
        fontWeight: "bold",
        fontSize: "14px"
      } 
    }, "DISCHARGE SUMMARY"),

    // Consultant Name
    React.createElement("div", { 
      style: { 
        backgroundColor: "#d3d3d3", 
        padding: "4px", 
        textAlign: "center",
        marginBottom: "6px",
        fontSize: "11px"
      } 
    }, "Consultant: Dr Anupam Kumar Chaurasia"),

    // Baby Details Section (if exists)
    // formData.dischargeData?.babyDetails?.length > 0 && React.createElement("div", null,
    //   React.createElement("div", { 
    //     style: { 
    //       backgroundColor: "#663399", 
    //       color: "white", 
    //       padding: "2px 8px", 
    //       textAlign: "center",
    //       marginBottom: "2px",
    //       fontWeight: "bold"
    //     } 
    //   }, "BABY DETAILS"),
    //   React.createElement("table", { style: styles.table },
    //     React.createElement("thead", null,
    //       React.createElement("tr", null,
    //         React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Baby No."),
    //         React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Sex"),
    //         React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Weight (gm)"),
    //         React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Date"),
    //         React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Time"),
    //         React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "APGAR Score")
    //       )
    //     ),
    //     React.createElement("tbody", null,
    //       formData.dischargeData.babyDetails.map((baby, index) => 
    //         React.createElement("tr", { key: index },
    //           React.createElement("td", { style: styles.tableCell }, baby.number || "--"),
    //           React.createElement("td", { style: styles.tableCell }, baby.sex || "--"),
    //           React.createElement("td", { style: styles.tableCell }, baby.weight || "--"),
    //           React.createElement("td", { style: styles.tableCell }, formatDate(baby.date) || "--"),
    //           React.createElement("td", { style: styles.tableCell }, baby.time || "--"),
    //           React.createElement("td", { style: styles.tableCell }, baby.apgar || "--")
    //         )
    //       )
    //     )
    //   )
    // ),

    // Patient Info Table
    React.createElement("table", { style: { width: "100%", borderCollapse: "collapse", marginBottom: "6px" } },
      React.createElement("tbody", null,
        React.createElement("tr", null,
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", width: "20%" } }, "REG NO"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", width: "30%" } }, patient?.registrationNumber || "--"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", width: "20%" } }, "ADMISSION NO"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", width: "30%" } }, patient?.ipdNumber || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "NAME"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, patient?.name || "--"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "HUSBAND"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, patient?.relativeName || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "AGE/SEX"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, 
            \`\${patient?.age || "--"}/\${patient?.gender || "--"}\`
          ),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "MOBILE NO"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, patient?.contactNumber || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "ADDRESS"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" }, colSpan: "3" }, patient?.address || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "ADMISSION DATE/TIME"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, formatDate(formData.admissionDate)),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, "DISCHARGE DATE/TIME"),
          React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px" } }, formatDate(formData.dateDischarged))
        )
      )
    ),

    // Diagnosis Section
    React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "DIAGNOSIS"),
      React.createElement("div", { 
        style: { 
          border: "1px solid black", 
          padding: "4px 8px", 
          marginBottom: "6px" 
        } 
      }, formData.diagnosis || "--")
    ),

    // Plan of Management
    React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "PLAN OF MANAGEMENT"),
      React.createElement("div", { 
        style: { 
          border: "1px solid black", 
          padding: "4px 8px", 
          marginBottom: "6px" 
        } 
      }, formData.treatment || "--")
    ),

    // Operation Details if present
    formData.operation && React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "OPERATION"),
      React.createElement("div", { 
        style: { 
          border: "1px solid black", 
          padding: "4px 8px", 
          marginBottom: "6px" 
        } 
      }, formData.operation)
    ),

    // Baby Notes if present
    formData.babyNotes && React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "BABY NOTES BIRTH NO:- " + (formData.babyNotes.birthNo || "")),
      React.createElement("table", { 
        style: { 
          width: "100%", 
          borderCollapse: "collapse", 
          marginBottom: "6px"
        } 
      },
        React.createElement("thead", null,
          React.createElement("tr", null,
            React.createElement("th", { style: { border: "1px solid black", padding: "2px 4px", backgroundColor: "#f0f0f0" } }, "DATE OF BIRTH"),
            React.createElement("th", { style: { border: "1px solid black", padding: "2px 4px", backgroundColor: "#f0f0f0" } }, "TIME OF BIRTH"),
            React.createElement("th", { style: { border: "1px solid black", padding: "2px 4px", backgroundColor: "#f0f0f0" } }, "SEX OF BABY"),
            React.createElement("th", { style: { border: "1px solid black", padding: "2px 4px", backgroundColor: "#f0f0f0" } }, "WEIGHT OF BABY"),
            React.createElement("th", { style: { border: "1px solid black", padding: "2px 4px", backgroundColor: "#f0f0f0" } }, "APGAR SCORE")
          )
        ),
        React.createElement("tbody", null,
          React.createElement("tr", null,
            React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", textAlign: "center" } }, formatDate(formData.babyNotes?.dateOfBirth)),
            React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", textAlign: "center" } }, formData.babyNotes?.timeOfBirth || "--"),
            React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", textAlign: "center" } }, formData.babyNotes?.sex || "--"),
            React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", textAlign: "center" } }, formData.babyNotes?.weight ? \`\${formData.babyNotes.weight} GM\` : "--"),
            React.createElement("td", { style: { border: "1px solid black", padding: "2px 4px", textAlign: "center" } }, formData.babyNotes?.apgarScore || "--")
          )
        )
      ),
      // Remarks section under baby notes
      React.createElement("div", { style: { marginTop: "2px", fontWeight: "bold" } }, "REMARKS"),
      React.createElement("div", { 
        style: { 
          border: "1px solid black", 
          padding: "4px 8px", 
          marginBottom: "6px" 
        } 
      }, formData.babyNotes?.remarks || "--"),
      // Baby blood group
      formData.babyNotes?.bloodGroup && React.createElement("div", { style: { marginTop: "2px", color: "red" } }, 
        "BABY BLOOD GROUP: " + formData.babyNotes.bloodGroup
      )
    ),

    // Case Summary
    React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "CASE SUMMARY"),
      React.createElement("div", { 
        style: { 
          border: "1px solid black", 
          padding: "4px 8px", 
          marginBottom: "6px" 
        } 
      }, formData.clinicalSummary || "--")
    ),

    // On Examination
    React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "ON EXAMINATION"),
      React.createElement("div", { 
        style: { 
          border: "1px solid black", 
          padding: "4px 8px", 
          marginBottom: "6px" 
        } 
      }, formData.examination || "--")
    ),

    // Doctor's Signature
    React.createElement("div", { 
      style: { 
        width: "100%", 
        textAlign: "right", 
        marginTop: "15px",
        paddingRight: "50px"
      }
    },
      React.createElement("div", { style: { fontSize: "12px", fontWeight: "bold" } }, 
        "Doctor's Signature"
      )
    )
  );
}`;

export const configBasedDischargeSummaryTemplate = `
(formData, patient, hospital, formConfig, ref) => {
  // Helper functions
  const formatLabel = (label) => {
    if (!label) return "";
    const regex = /^\([^)]+\)|^(?:\\S+\\s?){1,3}/;
    const match = label.match(regex);
    return match ? match[0].trim() : label;
  };

  const formatDate = (date) => {
    if (!date) return "--";
    return new Date(date).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
  };

  const hasValue = (obj) => {
    if (!obj) return false;
    return Object.values(obj).some(value => value !== "" && value !== null && value !== undefined);
  };

  // Get the form configuration - use provided formConfig
  const config = formConfig || {
    sections: [
      {
        id: "patientInfo",
        title: "Patient Information",
        fields: []
      },
      {
        id: "clinicalInfo",
        fields: []
      }
    ]
  };

  // Helper function to get field value
  const getFieldValue = (field) => {
    // For patient info section, get values from patient object
    if (field.id in patient) {
      return patient[field.id];
    }

    // For other fields, get from formData
    if (field.id.includes(".")) {
      // Handle nested paths (e.g., "vitals.admission.bloodPressure")
      return field.id.split(".").reduce((obj, key) => obj?.[key], formData);
    }

    // Handle special cases
    switch (field.type) {
      case "multiselect":
        if (field.id === "diagnosis") {
          return formData.diagnosis ? formData.diagnosis.split(", ").map(d => ({ name: d })) : [];
        }
        if (field.id === "comorbidities") {
          return formData.comorbidities || [];
        }
        return formData[field.id] || [];
      case "investigations":
        return formData.investigations || [];
      case "medicineAdvice":
        return formData[field.id] || [];
      case "vitals":
        return formData.vitals?.[field.prefix] || {};
      default:
        return formData[field.id];
    }
  };

  // Helper function to render field based on type
  const renderField = (field) => {
    const value = getFieldValue(field);
   
    if (!field || !field.id) return null;

    switch (field.type) {
      case "vitals":
        const vitalsData = formData.vitals?.[field.prefix];
        return hasValue(vitalsData) && React.createElement("div", { style: styles.section },
          React.createElement("span", { style: styles.sectionTitle }, \`\${field.label}:\`),
          React.createElement("span", { style: styles.sectionContent },
            [
              vitalsData.bloodPressure && \`BP: \${vitalsData.bloodPressure} mmHg\`,
              vitalsData.heartRate && \`Heart Rate: \${vitalsData.heartRate} bpm\`,
              vitalsData.temperature && \`Temperature: \${vitalsData.temperature}°C\`,
              vitalsData.oxygenSaturation && \`O2 Saturation: \${vitalsData.oxygenSaturation}%\`,
              vitalsData.respiratoryRate && \`Respiratory Rate: \${vitalsData.respiratoryRate} breaths/min\`
            ].filter(Boolean).join(", ")
          )
        );

      case "investigations":
        return value && value.length > 0 && React.createElement("div", null,
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: { border: "1px solid black" } },
          React.createElement("div", { style: { marginLeft: "10px" } },
            value.filter(inv => inv.name && inv.report).map((investigation, index) => {
              const hasFindings = investigation.report?.findings && 
                Object.values(investigation.report.findings).some(value => value);
              const hasImpression = investigation.report?.impression && 
                Object.values(investigation.report.impression).some(value => value);
              const reportEntries = Object.entries(investigation.report || {})
                .filter(([_, testData]) => testData.value);
              const halfLength = Math.ceil(reportEntries.length / 2);

              return React.createElement("div", {
                key: index,
                style: {
                  marginLeft: "5px",
                  marginBottom: "3px",
                  display: "flex",
                  flexDirection: hasFindings || hasImpression ? "row" : "column"
                }
              },
                React.createElement("div", null,
                  React.createElement("div", { 
                    style: { 
                      fontSize: "10px", 
                      marginBottom: "2px", 
                      fontWeight: "bold" 
                    } 
                  },
                    \`\${investigation.name.toUpperCase()} (\${formatDate(investigation.date)})\`
                  )
                ),
                (hasFindings || hasImpression) ? 
                  React.createElement("div", { style: { display: "flex", flexDirection: "row", width: "100%" } },
                    React.createElement("div", { style: { width: "100%" } },
                      investigation.report.findings && React.createElement("div", {
                        style: {
                          marginLeft: "50px",
                          fontSize: "9px",
                          width: "100%"
                        }
                      },
                        React.createElement("div", { style: { width: "100%", fontSize: "9px" } },
                          React.createElement("span", null, investigation.report.findings.value)
                        )
                      ),
                      investigation.report.impression && React.createElement("div", {
                        style: {
                          marginLeft: "50px",
                          fontSize: "9px",
                          width: "100%",
                          marginTop: "2px"
                        }
                      },
                        React.createElement("div", { style: { width: "100%", fontSize: "9px" } },
                          React.createElement("span", null, investigation.report.impression.value)
                        )
                      )
                    )
                  ) :
                  React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                    React.createElement("div", { style: { width: "50%" } },
                      reportEntries.slice(0, halfLength).map(([testName, testData]) =>
                        testData.value && React.createElement("div", {
                          key: testName,
                          style: { 
                            display: "flex", 
                            flexDirection: "row", 
                            fontSize: "9px", 
                            marginBottom: "1px",
                            marginLeft: "5px" 
                          }
                        },
                          React.createElement("div", { style: { width: "60%", fontSize: "9px" } },
                            React.createElement("span", null, formatLabel(testData.label) || testName)
                          ),
                          React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                            React.createElement("span", null, testData.value)
                          ),
                          testData.unit && React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                            React.createElement("span", null, testData.unit)
                          )
                        )
                      )
                    ),
                    React.createElement("div", { style: { width: "50%" } },
                      reportEntries.slice(halfLength).map(([testName, testData]) =>
                        testData.value && React.createElement("div", {
                        key: testName,
                          style: { 
                            display: "flex", 
                            flexDirection: "row", 
                            fontSize: "9px", 
                            marginBottom: "1px",
                            marginLeft: "5px" 
                          }
                        },
                          React.createElement("div", { style: { width: "60%", fontSize: "9px" } },
                            React.createElement("span", null, formatLabel(testData.label) || testName)
                          ),
                          React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                            React.createElement("span", null, testData.value)
                          ),
                          testData.unit && React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                            React.createElement("span", null, testData.unit)
                      )
                    )
                )
              )
            )
              );
            })
           )
          )
        );

      case "medicineAdvice":
        return value && value.length > 0 && React.createElement("div", { style: styles.section },
          React.createElement("span", { style: styles.sectionTitle ,}, field.label),
          React.createElement("span", { style: { border: "1px solid black" } },
          React.createElement("div", { style: { marginLeft: "20px" } },
            value.filter(med => med.name || med.dosage || med.duration).map((med, index) => 
              React.createElement("div", { 
                key: index,
                style: { fontSize: "11px", marginBottom: "2px" }
              },
                React.createElement("span", { style: { display: "inline-block", width: "20px" } },
                  \`\${index + 1}.\`
                ),
                React.createElement("span", { style: { display: "inline-block", minWidth: "200px" } },
                  med.name
                ),
                (med.dosage && med.dosage !== "0-0-0") && React.createElement("span", { style: { display: "inline-block", minWidth: "150px" } },
                  \`Dosage: \${med.dosage}\`
                ),
                med.duration && React.createElement("span", { style: { display: "inline-block" } },
                  \`\${med.duration} Days\`
                )
              )
            )
          )
         )
        );

      case "multiselect":
        if (field.id === "comorbidities") {
          const comorbiditiesString = value?.filter(c => c.name).map(c => c.name).join(", ");
          return comorbiditiesString && React.createElement("div", { style: styles.section },
            React.createElement("span", { style: styles.sectionTitle }, field.label + ":"),
            React.createElement("span", { style: styles.sectionContent }, comorbiditiesString)
          );
        }
        if (field.id === "diagnosis") {
          return React.createElement("div", { style: styles.section },
            React.createElement("span", { style: styles.sectionTitle }, field.label + ":"),
            React.createElement("span", { style: styles.sectionContent }, 
              Array.isArray(value) ? value.map(item => item.name).join(", ") : value
            )
          );
        }
        return React.createElement("div", { style: styles.section },
          React.createElement("span", { style: styles.sectionTitle }, field.label + ":"),
          React.createElement("span", { style: styles.sectionContent }, 
            Array.isArray(value) ? value.join(", ") : value
          )
        );

      case "date":
        return React.createElement("div", { style: styles.section },
          React.createElement("span", { style: styles.sectionTitle }, field.label + ":"),
          React.createElement("span", { style: styles.sectionContent }, formatDate(value))
        );
       case "babyTable":
        return formData?.babyDetails?.length > 0 && React.createElement("div", null,
      React.createElement("div", { 
        style: { 
          backgroundColor: "#663399", 
          color: "white", 
          padding: "2px 8px", 
          textAlign: "center",
          marginBottom: "2px",
          fontWeight: "bold"
        } 
      }, "BABY DETAILS"),
      React.createElement("table", { style: styles.table },
        React.createElement("thead", null,
          React.createElement("tr", null,
            React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Baby No."),
            React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Sex"),
            React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Weight (gm)"),
            React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Date"),
            React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Time"),
            React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "APGAR Score")
          )
        ),
        React.createElement("tbody", null,
          formData?.babyDetails.map((baby, index) => 
            React.createElement("tr", { key: index },
              React.createElement("td", { style: styles.tableCell }, baby.number || "--"),
              React.createElement("td", { style: styles.tableCell }, baby.sex || "--"),
              React.createElement("td", { style: styles.tableCell }, baby.weight || "--"),
               React.createElement("td", { style: styles.tableCell }, formatDate(baby.date) || "--"),
              React.createElement("td", { style: styles.tableCell }, baby.time || "--"),
              React.createElement("td", { style: styles.tableCell }, baby.apgar || "--")
            )
          )
        )
      )
    );
      case "textarea":
      case "text":
      default:
        return value && React.createElement("div", { style: styles.section },
          React.createElement("span", { style: styles.sectionTitle }, field.label + ":"),
          React.createElement("span", { style: styles.sectionContent }, value)
        );
    }
  };

  // Common styles
  const styles = {
    section: {
      marginBottom: "4px",
      display: "flex",
      flexDirection: "column"
    },
    sectionTitle: {
      fontWeight: "bold",
      backgroundColor: "#663399",
      color: "white",
      textAlign: "center",
      padding: "2px 8px",
      marginBottom: "2px",
    },
    sectionContent: {
      padding: "4px 8px",
      border: "1px solid black",
      fontSize: "12px",
      whiteSpace: "pre-line"
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "6px"
    },
    tableCell: {
      border: "1px solid black",
      padding: "2px 4px",
      textAlign: "center",
      fontSize: "11px"
    }
  };

  return React.createElement("div", { ref: ref, style: { padding: "20px",paddingBottom:"25px" }, className: "print-content" },
    // Header with purple background
    React.createElement("div",  {},
      React.createElement(HospitalHeader, { hospitalInfo: hospital })
    ),

    // Discharge Summary Title
    React.createElement("div", { 
      style: { 
        backgroundColor: "#663399", 
        color: "white", 
        padding: "4px 8px", 
        textAlign: "center",
        marginBottom: "4px",
        fontWeight: "bold",
        fontSize: "14px"
      } 
    }, "DISCHARGE SUMMARY"),

    // Consultant Name
    React.createElement("div", { 
      style: { 
        backgroundColor: "#d3d3d3", 
        padding: "4px", 
        textAlign: "center",
        marginBottom: "6px",
        fontSize: "11px"
      } 
    }, "Consultant: Dr Anupam Kumar Chaurasia"),

    // Baby Details Section (if exists)
    

    // Patient Info Table
    React.createElement("table", { style: styles.table },
      React.createElement("tbody", null,
        React.createElement("tr", null,
          React.createElement("td", { style: styles.tableCell }, "REG NO"),
          React.createElement("td", { style: styles.tableCell }, patient?.registrationNumber || "--"),
          React.createElement("td", { style: styles.tableCell }, "ADMISSION NO"),
          React.createElement("td", { style: styles.tableCell }, patient?.ipdNumber || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: styles.tableCell }, "NAME"),
          React.createElement("td", { style: styles.tableCell }, patient?.name || "--"),
          React.createElement("td", { style: styles.tableCell }, patient?.relation?.toUpperCase() || "HUSBAND"),
          React.createElement("td", { style: styles.tableCell }, patient?.[patient?.relation] || patient?.husbandName || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: styles.tableCell }, "AGE/SEX"),
          React.createElement("td", { style: styles.tableCell }, \`\${patient?.age || "--"}/\${patient?.gender || "--"}\`),
          React.createElement("td", { style: styles.tableCell }, "MOBILE NO"),
          React.createElement("td", { style: styles.tableCell }, patient?.contactNumber || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: styles.tableCell }, "ADDRESS"),
          React.createElement("td", { style: { ...styles.tableCell, textAlign: "left" }, colSpan: "3" }, patient?.address || "--")
        ),
        React.createElement("tr", null,
          React.createElement("td", { style: styles.tableCell }, "ADMISSION DATE/TIME"),
          React.createElement("td", { style: styles.tableCell }, formatDate(formData.admissionDate)+(formData.admittedTime? " At "+formData.admittedTime:"")),
          React.createElement("td", { style: styles.tableCell }, "DISCHARGE DATE/TIME"),
          React.createElement("td", { style: styles.tableCell }, formatDate(formData.dateDischarged)+(formData.timeDischarged? " At "+formData.timeDischarged:""))
        )
      )
    ),

    // Render remaining sections from formConfig
    (formConfig?.sections || [])
      .filter(section => section.id !== "patientInfo") // Skip patient info section
      .map((section, sectionIndex) => 
        React.createElement("div", { key: sectionIndex, style: { marginBottom: "4px" } },
          section.fields
            .filter(field => !field.hidden)
            .map((field, fieldIndex) => renderField(field))
        )
    ),

    // Doctor's Signature
    React.createElement("div", { 
      style: { 
        width: "100%", 
        textAlign: "right", 
        marginTop: "15px",
        paddingRight: "50px"
      }
    },
      React.createElement("div", { style: { fontSize: "12px", fontWeight: "bold" } }, 
        "Doctor's Signature"
      )
    )
  );
}`;

export const hybridDischargeSummaryTemplate = `
(formData, patient, hospital, formConfig, ref) => {
  // Helper functions
  const formatLabel = (label) => {
    if (!label) return "";
    const regex = /^\([^)]+\)|^(?:\\S+\\s?){1,3}/;
    const match = label.match(regex);
    return match ? match[0].trim() : label;
  };

  const formatDate = (date) => {
    if (!date) return "--";
    return new Date(date).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
  };

  const hasValue = (obj) => {
    if (!obj) return false;
    return Object.values(obj).some(value => value !== "" && value !== null && value !== undefined);
  };

  // Get field value helper
  const getFieldValue = (field) => {
    if (field.id in patient) {
      return patient[field.id];
    }

    if (field.id.includes(".")) {
      return field.id.split(".").reduce((obj, key) => obj?.[key], formData);
    }

    switch (field.type) {
      case "multiselect":
        if (field.id === "diagnosis") {
          return formData.diagnosis ? formData.diagnosis.split(", ").map(d => ({ name: d })) : [];
        }
        if (field.id === "comorbidities") {
          return formData.comorbidities || [];
        }
        return formData[field.id] || [];
      case "investigations":
        return formData.investigations || [];
      case "medicineAdvice":
        return formData[field.id] || [];
      case "vitals":
        return formData.vitals?.[field.prefix] || {};
      default:
        return formData[field.id];
    }
  };

  // Styles
  // const styles = {
  //   section: {
  //     marginBottom: "4px",
  //     display: "flex",
  //     flexDirection: "column"
  //   },
  //   sectionTitle: {
  //     fontWeight: "bold",
  //     backgroundColor: "#663399",
  //     color: "white",
  //     textAlign: "center",
  //     padding: "2px 8px",
  //     marginBottom: "2px",
  //   },
  //   sectionContent: {
  //     padding: "4px 8px",
  //     border: "1px solid black",
  //     fontSize: "12px",
  //     whiteSpace: "pre-line"
  //   },
  //   table: {
  //     width: "100%",
  //     borderCollapse: "collapse",
  //     marginBottom: "6px"
  //   },
  //   tableCell: {
  //     border: "1px solid black",
  //     padding: "2px 4px",
  //     fontSize: "11px"
  //   }
  // };

  // Field renderer
  const renderField = (field) => {
    const value = getFieldValue(field);
   
    if (!field || !field.id) return null;

    switch (field.type) {
      case "vitals":
        const vitalsData = formData.vitals?.[field.prefix];
        return hasValue(vitalsData) && React.createElement("div", { style: styles.section },
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: styles.sectionContent },
            [
              vitalsData.bloodPressure && \`BP: \${vitalsData.bloodPressure} mmHg\`,
              vitalsData.heartRate && \`Heart Rate: \${vitalsData.heartRate} bpm\`,
              vitalsData.temperature && \`Temperature: \${vitalsData.temperature}°C\`,
              vitalsData.oxygenSaturation && \`O2 Saturation: \${vitalsData.oxygenSaturation}%\`,
              vitalsData.respiratoryRate && \`Respiratory Rate: \${vitalsData.respiratoryRate} breaths/min\`
            ].filter(Boolean).join(", ")
          )
        );

      case "investigations":
        return value && value.length > 0 && React.createElement("div", null,
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: { border: "1px solid black" } },
            React.createElement("div", { style: { marginLeft: "10px" } },
              value.filter(inv => inv.name && inv.report).map((investigation, index) => {
                const hasFindings = investigation.report?.findings && 
                  Object.values(investigation.report.findings).some(value => value);
                const hasImpression = investigation.report?.impression && 
                  Object.values(investigation.report.impression).some(value => value);
                const reportEntries = Object.entries(investigation.report || {})
                  .filter(([_, testData]) => testData.value);
                const halfLength = Math.ceil(reportEntries.length / 2);

                return React.createElement("div", {
                  key: index,
                  style: {
                    marginLeft: "5px",
                    marginBottom: "3px",
                    display: "flex",
                    flexDirection: hasFindings || hasImpression ? "row" : "column"
                  }
                },
                  React.createElement("div", null,
                    React.createElement("div", { 
                      style: { 
                        fontSize: "10px", 
                        marginBottom: "2px", 
                        fontWeight: "bold" 
                      } 
                    },
                      \`\${investigation.name.toUpperCase()} (\${formatDate(investigation.date)})\`
                    )
                  ),
                  (hasFindings || hasImpression) ? 
                    React.createElement("div", { style: { display: "flex", flexDirection: "row", width: "100%" } },
                      React.createElement("div", { style: { width: "100%" } },
                        investigation.report.findings && React.createElement("div", {
                          style: {
                            marginLeft: "50px",
                            fontSize: "9px",
                            width: "100%"
                          }
                        },
                          React.createElement("div", { style: { width: "100%", fontSize: "9px" } },
                            React.createElement("span", null, investigation.report.findings.value)
                          )
                        ),
                        investigation.report.impression && React.createElement("div", {
                          style: {
                            marginLeft: "50px",
                            fontSize: "9px",
                            width: "100%",
                            marginTop: "2px"
                          }
                        },
                          React.createElement("div", { style: { width: "100%", fontSize: "9px" } },
                            React.createElement("span", null, investigation.report.impression.value)
                          )
                        )
                      )
                    ) :
                    React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                      React.createElement("div", { style: { width: "50%" } },
                        reportEntries.slice(0, halfLength).map(([testName, testData]) =>
                          testData.value && React.createElement("div", {
                            key: testName,
                            style: { 
                              display: "flex", 
                              flexDirection: "row", 
                              fontSize: "9px", 
                              marginBottom: "1px",
                              marginLeft: "5px" 
                            }
                          },
                            React.createElement("div", { style: { width: "60%", fontSize: "9px" } },
                              React.createElement("span", null, formatLabel(testData.label) || testName)
                            ),
                            React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                              React.createElement("span", null, testData.value)
                            ),
                            testData.unit && React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                              React.createElement("span", null, testData.unit)
                            )
                          )
                        )
                      ),
                      React.createElement("div", { style: { width: "50%" } },
                        reportEntries.slice(halfLength).map(([testName, testData]) =>
                          testData.value && React.createElement("div", {
                            key: testName,
                            style: { 
                              display: "flex", 
                              flexDirection: "row", 
                              fontSize: "9px", 
                              marginBottom: "1px",
                              marginLeft: "5px" 
                            }
                          },
                            React.createElement("div", { style: { width: "60%", fontSize: "9px" } },
                              React.createElement("span", null, formatLabel(testData.label) || testName)
                            ),
                            React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                              React.createElement("span", null, testData.value)
                            ),
                            testData.unit && React.createElement("div", { style: { width: "20%", fontSize: "9px" } },
                              React.createElement("span", null, testData.unit)
                            )
                          )
                        )
                      )
                    )
                );
              })
            )
          )
        );

      case "medicineAdvice":
        return value && value.length > 0 && React.createElement("div", { style: styles.section },
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: { border: "1px solid black" } },
            React.createElement("div", { style: { marginLeft: "20px" } },
              value.filter(med => med.name || med.dosage || med.duration).map((med, index) => 
                React.createElement("div", { 
                  key: index,
                  style: { fontSize: "11px", marginBottom: "2px" }
                },
                  React.createElement("span", { style: { display: "inline-block", width: "20px" } },
                    \`\${index + 1}.\`
                  ),
                  React.createElement("span", { style: { display: "inline-block", minWidth: "200px" } },
                    med.name
                  ),
                  (med.dosage && med.dosage !== "0-0-0") && React.createElement("span", { style: { display: "inline-block", minWidth: "150px" } },
                    \`Dosage: \${med.dosage}\`
                  ),
                  med.duration && React.createElement("span", { style: { display: "inline-block" } },
                    \`\${med.duration} Days\`
                  )
                )
              )
            )
          )
        );

      case "multiselect":
        if (field.id === "comorbidities") {
          const comorbiditiesString = value?.filter(c => c.name).map(c => c.name).join(", ");
          return comorbiditiesString && React.createElement("div", { style: styles.section },
            React.createElement("div", { style: styles.sectionTitle }, field.label),
            React.createElement("div", { style: styles.sectionContent }, comorbiditiesString)
          );
        }
        if (field.id === "diagnosis") {
          return React.createElement("div", { style: styles.section },
            React.createElement("div", { style: styles.sectionTitle }, field.label),
            React.createElement("div", { style: styles.sectionContent }, 
              Array.isArray(value) ? value.map(item => item.name).join(", ") : value
            )
          );
        }
        return React.createElement("div", { style: styles.section },
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: styles.sectionContent }, 
            Array.isArray(value) ? value.join(", ") : value
          )
        );

      case "date":
        return React.createElement("div", { style: styles.section },
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: styles.sectionContent }, formatDate(value))
        );

      case "babyTable":
        return formData?.babyDetails?.length > 0 && React.createElement("div", null,
          React.createElement("div", { style: styles.sectionTitle }, "BABY DETAILS"),
          React.createElement("table", { style: styles.table },
            React.createElement("thead", null,
              React.createElement("tr", null,
                React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Baby No."),
                React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Sex"),
                React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Weight (gm)"),
                React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Date"),
                React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "Time"),
                React.createElement("th", { style: { ...styles.tableCell, backgroundColor: "#f0f0f0", fontWeight: "bold" } }, "APGAR Score")
              )
            ),
            React.createElement("tbody", null,
              formData?.babyDetails.map((baby, index) => 
                React.createElement("tr", { key: index },
                  React.createElement("td", { style: styles.tableCell }, baby.number || "--"),
                  React.createElement("td", { style: styles.tableCell }, baby.sex || "--"),
                  React.createElement("td", { style: styles.tableCell }, baby.weight || "--"),
                  React.createElement("td", { style: styles.tableCell }, formatDate(baby.date) || "--"),
                  React.createElement("td", { style: styles.tableCell }, baby.time || "--"),
                  React.createElement("td", { style: styles.tableCell }, baby.apgar || "--")
                )
              )
            )
          )
        );

      case "textarea":
      case "text":
      default:
        return value && React.createElement("div", { style: styles.section },
          React.createElement("div", { style: styles.sectionTitle }, field.label),
          React.createElement("div", { style: styles.sectionContent }, value)
        );
    }
  };

  return React.createElement("div", { ref: ref, style: styles.page, className: "print-content" },
    React.createElement("div", { className: " print:block mb-2" },
      React.createElement(HospitalHeader, { hospitalInfo: hospital })
    ),
    React.createElement("div", { style: styles.title }, "Discharge Summary"),
    
    // Patient Info Section
    React.createElement("div", { style: { ...styles.section } },
      React.createElement("div", { style: { ...styles.patientInfoSection } },
        React.createElement("div", { style: { ...styles.row, marginBottom: "2px" } },
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Name: "),
            React.createElement("span", { style: styles.value }, patient?.name || "--")
          ),
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Age/Gender: "),
            React.createElement("span", { style: styles.value }, 
              patient?.age && patient?.gender ? \`\${patient?.age} yrs/\${patient?.gender}\` : "--"
            )
          ),
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "UHID No: "),
            React.createElement("span", { style: styles.value }, patient?.registrationNumber || "--")
          )
        ),
        React.createElement("div", { style: { ...styles.row, marginBottom: "2px" } },
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "IPD No: "),
            React.createElement("span", { style: styles.value }, patient?.ipdNumber || "--")
          ),
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Admit Date: "),
            React.createElement("span", { style: styles.value }, formatDate(formData.admissionDate))
          ),
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Discharge Date: "),
            React.createElement("span", { style: styles.value }, formatDate(formData.dateDischarged))
          )
        ),
        React.createElement("div", { style: styles.row },
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Room: "),
            React.createElement("span", { style: styles.value }, patient?.roomNumber || "--")
          ),
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Contact: "),
            React.createElement("span", { style: styles.value }, patient?.contactNumber || "--")
          ),
          React.createElement("span", { style: styles.infoItem },
            React.createElement("span", { style: styles.label }, "Address: "),
            React.createElement("span", { style: { ...styles.value, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" } },
              patient?.address || "--"
            )
          )
        )
      )
    ),

    // Render form config sections
    (formConfig?.sections || [])
      .filter(section => section.id !== "patientInfo")
      .map((section, sectionIndex) => 
        React.createElement("div", { key: sectionIndex },
          section.fields
            .filter(field => !field.hidden)
            .map((field, fieldIndex) => renderField(field))
        )
      ),

    // Doctor's Signature
    React.createElement("div", { 
      style: { 
        width: "100%", 
        textAlign: "right", 
        marginTop: "15px",
        paddingRight: "50px"
      }
    },
      React.createElement("div", { style: { fontSize: "12px", fontWeight: "bold" } }, 
        "Doctor's Signature"
      )
    )
  );
}`;
