export const opdPrescriptionTemplateString = `
( patient, vitals, prescription, labTests, selectedComorbidities, hospital, ref) => {
  const capitalizeAll = (str) => {
    return str?.toUpperCase() || '';
  };

  const VitalItem = ({ label, value, unit }) => {
    if (value === undefined || value === null || value === '') {
      return null;
    }
    return React.createElement("div", { style: { fontSize: "8px" } },
      React.createElement("div", null,
        React.createElement("span", { style: { fontWeight: "bold" } },
          label === "O2" ? React.createElement(React.Fragment, null, "O", React.createElement("sub", null, "2"), "%") : capitalizeAll(label),
          ": "
        ),
        React.createElement("span", null,
          value, " ", unit
        )
      )
    );
  };

  const vitalItems = [
    { label: "Temperature", value: vitals.temperature, unit: "°C" },
    { label: "Heart Rate", value: vitals.heartRate, unit: "bpm" },
    { label: "Blood Pressure", value: vitals.bloodPressure, unit: "mmHg" },
    { label: "Respiratory Rate", value: vitals.respiratoryRate, unit: "bpm" },
    { label: "Height", value: vitals.height, unit: "cm" },
    { label: "Weight", value: vitals.weight, unit: "kg" },
    { label: "BMI", value: vitals.bmi, unit: "" },
    { label: "O2", value: vitals.oxygenSaturation, unit: "%" },
  ];

  const presentVitals = vitalItems.filter(item => 
    item.value !== undefined && item.value !== null && item.value !== ''
  );

  return React.createElement("div", { ref: ref, style: { width: "100%",padding: "25px" } ,className: "print-content"},
    React.createElement("div", { style: { paddingBottom: "5px" } },
      React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px", position: "relative" } },
        React.createElement("img", {
          src: hospital?.hospitalLogo2Blob,
          alt: "Brain Icon",
          style: { width: "100px", height: "80px" }
        }),
        React.createElement("div", { style: { flex: 1, textAlign: "center", position: "relative", marginTop: "-10px" } },
          React.createElement("h1", { 
            style: { 
              fontSize: "56px",
              color: "#1a237e",
              fontWeight: "bold"
            }
          }, "आद्‌या न्यूरो सेन्टर")
        ),
        React.createElement("img", {
          src: hospital?.hospitalLogo2Blob,
          alt: "Brain Icon",
          style: { width: "100px", height: "80px" }
        })
      ),
      React.createElement("div", { style: { textAlign: "center", position: "relative", marginTop: "-10px" } },
        React.createElement("p", { style: { fontSize: "14px", color: "#333333", marginBottom: "2px", fontWeight: "bold" } }, 
          "मोहल्लादार के अदर, मुरारपुर मोड़ जी0 बी0 रोड, गया"
        ),
        React.createElement("div", { style: { display: "flex", justifyContent: "center", gap: "20px", fontWeight: "bold" } },
          React.createElement("p", { style: { fontSize: "14px", color: "#333333" } },
            "Mob: 7463811885"
          ),
          React.createElement("p", { style: { fontSize: "14px", color: "#333333" } },
            "Mob: 9199029251"
          )
        )
      ),
      React.createElement("div", { style: { display: "flex", justifyContent: "space-between", position: "relative", marginTop: "-30px" } },
        React.createElement("div", null,
          React.createElement("h2", { style: { fontSize: "24px", color: "#1a237e", fontWeight: "bold" } },
            "डा0 जयेन्द्र कुमार"
          ),
          React.createElement("p", { style: { fontSize: "10px", color: "#333333", fontWeight: "bold" } },
            "M.B.B.S, M.ch (Neurosurgery)",
            React.createElement("br"),
            "Associate Professor (J.N.M.M.C.H Gaya)",
            React.createElement("br"),
            "Consultant Neurosurgeon AIIMS, Gaya",
            React.createElement("br"),
            "Ex Assistant Professor N.M.C.H., Sasaram",
            React.createElement("br"),
            "Ex Senior Resident (Rims Ranchi)",
            React.createElement("br"),
            "Specialised in Microscopic",
            React.createElement("br"),
            "Endoscopic Brain & Spine Surgery",
            React.createElement("br"),
            "Reg No.: 36623"
          )
        ),
        React.createElement("div", { style: { display: "flex", justifyContent: "space-between", fontSize: "10px", color: "#333333", fontWeight: "bold", position: "absolute", top: "100px", left: "200px" } },
          React.createElement("div", { style: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" } },
            "सोमवार से शनिवार",
            React.createElement("br"),
            "शाम 05:00 बजे से 07:00 बजे तक"
          )
        ),
        React.createElement("div", { style: { display: "flex", justifyContent: "space-between", fontSize: "10px", color: "#333333", fontWeight: "bold", position: "absolute", top: "100px", right: "150px" } },
          React.createElement("div", { style: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" } },
            "सोमवार से शनिवार",
            React.createElement("br"),
            "शाम 05:00 बजे से 07:00 बजे तक",
            React.createElement("br"),
            "रविवार",
            React.createElement("br"),
            "सुबह 09:00 बजे से 11:00 बजे तक"
          )
        ),
        React.createElement("div", { style: { display: "flex", justifyContent: "center", alignItems: "center" } },
          React.createElement("img", {
            src: hospital?.hospitalLogoBlob,
            alt: "Hospital Logo",
            style: { width: "100px", height: "140px", marginTop: "30px" }
          })
        ),
        React.createElement("div", { style: { display: "flex", textAlign: "right", flexDirection: "column" } },
          React.createElement("h2", { style: { fontSize: "24px", color: "#1a237e", fontWeight: "bold", marginBottom: "4px" } },
            "डा0 निभा"
          ),
          React.createElement("p", { style: { fontSize: "10px", color: "#333333", fontWeight: "bold" } },
            "M.B.B.S, MD (Biochemistry)",
            React.createElement("br"),
            "Assistant Professor IMCH, Sasaram",
            React.createElement("br"),
            "Ex Medical Officer",
            React.createElement("br"),
            "Sadar Hospital, Aurangabad",
            React.createElement("br"),
            "Ex Resident ANMMCH, Gaya",
            React.createElement("br"),
            "Reg No. 37738"
          )
        )
      )
    ),

    React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" } },
      React.createElement("div"),
      React.createElement("h1", { style: { fontSize: "15px", color: "#1a5f7a", fontWeight: "bold", textAlign: "center", flex: 1 } }, "OPD Prescription"),
      React.createElement("div", { style: { fontSize: "14px", color: "#2c3e50",fontWeight: "bold" } }, format(new Date(), "dd/MM/yyyy"))
    ),

    React.createElement("div", { style: { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px" } },
      React.createElement("div", { style: { display: "flex", gap: "5px" } },
        React.createElement("span", { style: { fontWeight: "bold", fontSize: "14px" } }, "Name:"),
        React.createElement("span", { style: { fontSize: "14px" } }, patient?.name || '')
      ),
      React.createElement("div", { style: { display: "flex", gap: "5px" } },
        React.createElement("span", { style: { fontWeight: "bold", fontSize: "14px" } }, "Age/Sex:"),
        React.createElement("span", { style: { fontSize: "14px" } }, \`\${patient?.age || ''}/ \${patient?.gender || ''}\`)
      ),
      React.createElement("div", { style: { display: "flex", gap: "5px" } },
        React.createElement("span", { style: { fontWeight: "bold", fontSize: "14px" } }, "UHID No:"),
        React.createElement("span", { style: { fontSize: "14px" } }, patient?.registrationNumber || '')
      ),
      React.createElement("div", { style: { display: "flex", gap: "5px" } },
        React.createElement("span", { style: { fontWeight: "bold", fontSize: "14px" } }, "Contact:"),
        React.createElement("span", { style: { fontSize: "14px" } }, patient?.contactNumber || '')
      )
    ),

    presentVitals.length > 0 && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Vitals"),
      React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "5px" } },
        presentVitals.map((item, index) => 
          React.createElement(VitalItem, { key: index, ...item })
        )
      )
    ),

    prescription?.chiefComplaints && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Chief Complaints"),
      React.createElement("div", { style: { fontSize: "14px" } }, prescription.chiefComplaints)
    ),

    prescription?.diagnosis && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Diagnosis"),
      React.createElement("div", { style: { fontSize: "14px" } }, prescription.diagnosis)
    ),

    selectedComorbidities?.length > 0 && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Comorbidities"),
      selectedComorbidities.map((comorbidity, index) =>
        React.createElement("div", { key: index, style: { fontSize: "14px" } }, comorbidity.name)
      )
    ),
         labTests?.length > 0 && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Lab Tests"),
      React.createElement("div", { style: { fontSize: "14px" } }, labTests.join(', '))
    ),
   prescription?.medications?.length > 0 &&
  React.createElement("div", { style: { marginBottom: "10px" } },
    prescription.medications.some(medication => medication.name) && (
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Medications")
    ),
    React.createElement("div", { style: { width: "100%" } },
      prescription.medications
        .filter(medication => medication.name) // Only include medications with a name
        .map((medication, index) =>
          React.createElement("div", { key: index, style: { display: "grid", gridTemplateColumns: "5% 35% 30% 30%", marginBottom: "3px", fontSize: "14px" } },
            React.createElement("span", null, \`\${index + 1}.\`),
            React.createElement("span", null, medication.name),
            React.createElement("span", null, medication.frequency),
            React.createElement("span", null, \`\${medication.duration} days\`)
          )
        )
    )
  ),


    prescription?.advice && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Advice"),
      React.createElement("div", { style: { fontSize: "14px" } }, prescription.advice)
    ),

    labTests?.length > 0 && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Lab Tests"),
      React.createElement("div", { style: { fontSize: "14px" } }, labTests.join(', '))
    ),

    prescription?.followUp && React.createElement("div", { style: { marginBottom: "10px" } },
      React.createElement("div", { style: { fontSize: "14px", fontWeight: "bold", color: "#34495e", marginBottom: "5px" } }, "Follow Up"),
      React.createElement("div", { style: { fontSize: "14px" } }, prescription.followUp)
    ),

  );
}`;