export const headerTemplateString = `
React.createElement("div", { className: "mb-2 border-b border-[#000000] pb-2" },
  React.createElement("div", { 
    style: { 
      display: "flex", 
      alignItems: "flex-start",
      gap: "10px",
      marginBottom: "10px",
      paddingTop: "10px"
    } 
  },
    React.createElement("img", {
      src: hospitalInfo?.hospitalLogo2Blob,
      alt: "Clinic Logo",
      style: {
        width: "140px",
        height: "120px",
        objectFit: "contain"
      }
    }),
    React.createElement("div", { style: { flex: 1 } },
      React.createElement("h1", { 
        className: "text-[72px] tracking-wide text-[#1a5f7a] uppercase font-bold",
        style: { 
        marginTop:"-20px"
        }
      }, hospitalInfo?.name),
      React.createElement("div", { 
        style: {
          display: "flex",
          fontSize: "12px",
          color: "#333333",
          marginTop: "5px",
          gap: "10px"
        }
      },
        React.createElement("div", {
          style: {
            border: "2px solid #000",
            borderRadius: "30px",
            padding: "4px 12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }
        },
          React.createElement("div", { style: { fontSize: "14px" }, className:"font-bold" }, 
            "गोलपत्थर से उत्तर, मुरारपुर मोड़ जी0 बी0 रोड, गया"
          ),
          React.createElement("div", { style: { fontSize: "16px" }, className:"font-bold" }, 
            "Mob:- 7463811885"
          )
        ),
        React.createElement("div", {
          style: {
            border: "2px solid #000",
            borderRadius: "30px",
            padding: "4px 25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }
        },
          React.createElement("div", { style: { fontSize: "14px" }, className:"font-bold" }, 
            "जेल गेट के सामने, गया"
          ),
          React.createElement("div", { style: { fontSize: "16px" }, className:"font-bold" }, 
            "Mob:- 9199029251"
          )
        )
      )
    )
  )
)
`;

export const headerTemplateString2 = `
React.createElement("div", { 
  className: "mb-2",
  style: {
    maxWidth: "794px",
    margin: "0 auto"
  }
},
  React.createElement("img", {
    src: hospitalInfo?.hospitalLogo2Blob,
    alt: "Hospital Header",
    style: {
      width: "100%",
      height: "auto",
      objectFit: "contain"
    }
  })
)
`;

export const headerTemplateString3 = `
React.createElement("div", { className: "" },
    React.createElement("div", { className: "flex items-center gap-4 w-full" },
      React.createElement("img", {
        src: hospitalInfo?.hospitalLogo2Blob,
        alt: "Medical Symbol",
        style: {
          width: "100px",
          height: "100px",
          objectFit: "contain"
        }
      }),
      React.createElement("div", { className: "flex-1 text-center" },
        React.createElement("h1", { 
          className: "text-[#000080] text-4xl font-bold mb-1",
          style: { fontFamily: "'Brush Script MT', cursive" }
        }, 
          hospitalInfo?.doctorName || "Dr. Shiv Narayan Singh"
        ),
        React.createElement("div", { className: "text-sm mb-1" },
          "M.S. (Gen. Surgery), M.C.H. (G.I. Surgery)"
        ),
        React.createElement("div", { className: "text-sm mb-1" },
          "F.M.A.S. (Fellow of Minimal Access Surgeons of India Given by AMASI)"
        ),
        React.createElement("div", { className: "text-sm mb-1" },
          "Assistant Professor (Dept. of Surgery)"
        ),
        React.createElement("div", { className: "text-sm" },
          "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE & HOSPITAL, GAYA"
        )
      ),
      React.createElement("img", {
        src: hospitalInfo?.hospitalLogo2Blob,
        alt: "Medical Symbol",
        style: {
          width: "100px",
          height: "100px",
          objectFit: "contain"
        }
      })
    )
  ,
   React.createElement("div", { className: "flex justify-end mb-2 mt-20" },
    React.createElement("div", { className: "text-sm" }, "Date ____________")
  )
 
    
   
   
   
   
  
)
`;

export const headerTemplateString4 = `
React.createElement("div", { className: "mb-2 border-b border-[#000000] pb-2" },
  React.createElement("div", { 
    style: { 
      display: "flex", 
      alignItems: "flex-start",
      gap: "10px",
      marginBottom: "10px",
      paddingTop: "10px"
    } 
  },
    React.createElement("img", {
      src: hospitalInfo?.hospitalLogo2Blob,
      alt: "Clinic Logo",
      style: {
        width: "140px",
        height: "120px",
        objectFit: "contain",
        marginTop:"-20px"
      }
    }),
    React.createElement("div", { style: { flex: 1, display: "flex",flexDirection:"column", alignItems: "center",justifyContent: "center",marginTop:"-20px",
          marginLeft:"-30px" } },
      React.createElement("h1", { 
        className: "text-[45px] tracking-wide text-[#1a5f7a] uppercase font-bold",
        style: { 
          
        }
      }, "Shivam Nursing Home"),
      React.createElement("div", { 
        style: {
          display: "flex",
          fontSize: "12px",
          color: "#333333",
          marginTop: "5px",
          gap: "10px"
        }
      },
        React.createElement("div", {
          style: {
            borderRadius: "30px",
            padding: "4px 12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }
        },
          React.createElement("div", { style: { fontSize: "20px" }, className:"font-bold" }, 
            "गोपाल पेट्रोल पंप के पास, पुलिस लाइन रोड, गया"
          )
        )
      )
    )
  )
)
`;
